body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../../mcbollinger/src/Images/background.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.landing-sec{
  background-color: rgba(173, 181, 189, 0.5);
  position: relative;
}

.landing-img {
  position: absolute;
  right: 0;
  bottom: 7vw;
  width: 40vw;
}

#Contact {
  margin: 0 5vw 5vh 5vw;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center
}